<template>
  <div class="videoAds">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item
        ><a href="/">{{ client.ClientName }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item><a href="/products">Products</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ $route.meta.surveyTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="authoring">
      <ProgressBar
        :stepIndex="stepIndex"
        :surveyID="surveyID"
        :stepsType="$route.name !== 'pack-test-audience' ? 'video' : 'pack'"
      />
      <section class="stepAuthor">
        <div class="questions">
          <!-- sample type -->
          <div class="quiz">
            <div class="quizTitle">
              <span v-html="langObj['s5-audience'].quiz"></span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span v-html="langObj['s5-audience'].tip"></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <div class="quizContent">
              <a-radio-group v-model.number="sampleType">
                <a-radio :style="radioStyle" :value="1">
                  General population
                </a-radio>
                <a-radio :style="radioStyle" :value="2">
                  {{ audienceName }}
                </a-radio>
                <a-radio :style="radioStyle" :value="3"> Custom </a-radio>
              </a-radio-group>
            </div>
          </div>
          <!-- category redefin -->
          <div class="quiz refine" v-show="sampleType === 2">
            <h5>Category usage</h5>
            <a-radio-group v-model.number="usageFrame">
              <a-radio :style="radioStyle" :value="3">
                Within last 3 months
              </a-radio>
              <a-radio :style="radioStyle" :value="6">
                Within last 6 months
              </a-radio>
              <a-radio :style="radioStyle" :value="12">
                Within last 12 months
              </a-radio>
            </a-radio-group>
            <!--  -->
          </div>
          <!-- custom refine -->
          <div class="quiz refine" v-show="sampleType !== 2">
            <h5>Gender</h5>
            <a-checkbox-group v-model="genders">
              <a-checkbox value="male" :disabled="sampleType === 1"
                >Male</a-checkbox
              >
              <a-checkbox value="female" :disabled="sampleType === 1"
                >Female</a-checkbox
              >
            </a-checkbox-group>
            <!--  -->
            <h5>Age</h5>
            <a-checkbox-group v-model="ages">
              <a-checkbox :disabled="sampleType === 1" value="18-24"
                >18 to 24</a-checkbox
              >
              <a-checkbox :disabled="sampleType === 1" value="25-34"
                >25 to 34</a-checkbox
              >
              <a-checkbox :disabled="sampleType === 1" value="35-49"
                >35 to 49</a-checkbox
              >
              <a-checkbox :disabled="sampleType === 1" value="50-59"
                >50 to 59</a-checkbox
              >
              <a-checkbox :disabled="sampleType === 1" value="60+"
                >60+</a-checkbox
              >
            </a-checkbox-group>
            <!--  -->
            <h5>Regions</h5>
            <p class="brief" v-html="langObj['s5-audience'].region"></p>
          </div>

          <!-- sample size -->
          <div class="quiz">
            <div class="quizTitle">
              <span v-html="langObj['s5-sample'].quiz"></span>
              <a-tooltip placement="topRight">
                <template slot="title">
                  <span v-html="langObj['s5-sample'].tip"></span>
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </div>
            <div class="quizContent">
              <p class="brief break">
                <span v-show="sampleSize === 200">200 (recommended)</span>
                <span v-show="sampleSize !== 200"
                  >{{ sampleSize }} (custom)</span
                >
                <a-button
                  @click="customSample = true"
                  size="small"
                  class="custAntBtn"
                  >Change</a-button
                >
              </p>

              <a-radio-group v-model.number="sampleSize" v-show="customSample">
                <a-radio :value="150"> 150 </a-radio>
                <a-radio :value="200"> 200 (recommended)</a-radio>
                <a-radio :value="250"> 250 </a-radio>
                <a-radio :value="300"> 300 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <CompletionProgress :checkList="quizs"></CompletionProgress>
      </section>
      <StepNaviBar
        :stepIndex="stepIndex"
        :nextDisable="false"
        @nextHandler="nextStep"
        :prevDisable="false"
        @prevHandler="prevStep"
        :prevBtnTooltip="
          $route.name === 'vid-ua-audience'
            ? 'Back to Customize'
            : 'Back to Key Metrics'
        "
        :conciergeAssistance="
          $route.name === 'pack-test-audience' ? true : false
        "
        nextBtnTooltip="Go to Launch"
        :stepsType="$route.name === 'pack-test-audience' ? 'pack' : 'video'"
      />
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";

import ProgressBar from "@/components/videoAds/ProgressBar.vue";
import StepNaviBar from "@/components/videoAds/StepNaviBar.vue";
import { mutations } from "@/utils/store.js";
import CompletionProgress from "@/components/general/CompletionProgress.vue";
import surveyPriceMixin from "@/mixins/surveyPriceCalculation.js";

export default {
  name: "Audience",
  components: {
    ProgressBar,
    StepNaviBar,
    CompletionProgress,
  },
  mixins: [surveyPriceMixin],
  data() {
    return {
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      sampleType: 1,
      sampleSize: 200,
      genders: ["male", "female"],
      ages: ["18-24", "25-34", "35-49", "50-59", "60+"],
      customSample: false,
      usageFrame: 3,
      stepIndex: 5,
      surveyID: 0,
      langObj: {},
      client: {},
      quizs: [],
      audienceName: "",
      breadcrumbTitle: "",
      editableSurvey: null,
      user: null,
    };
  },
  watch: {
    sampleType(newVal) {
      this.updateQuiz();

      let currentSurveyPriceData = jsUtils.getSurveyPrice();

      if (newVal === 2) {
        let surveyPriceSetData = {
          Audience: {
            categoryUser: this.usageFrame,
            genPop: this.sampleType === 1 ? 1 : 0,
            sampleSize: this.sampleSize,
          },
        };
        jsUtils.setSurveyPrice({
          ...currentSurveyPriceData,
          ...surveyPriceSetData,
        });
        mutations.setSurveyPriceCategoryGenPop(this.sampleType === 1 ? 1 : 0);
        mutations.setSurveyPriceCategoryUser(this.usageFrame);
      } else {
        mutations.setSurveyPriceCategoryUser(0);
        let surveyPriceSetData = {
          Audience: {
            ...currentSurveyPriceData.Audience,
            categoryUser: 0,
            genPop: this.sampleType === 1 ? 1 : 0,
          },
        };
        jsUtils.setSurveyPrice({
          ...currentSurveyPriceData,
          ...surveyPriceSetData,
        });
      }
      this.getSurveyTotalPrice();
    },
    usageFrame(newVal) {
      mutations.setSurveyPriceCategoryGenPop(this.sampleType === 1 ? 1 : 0);
      mutations.setSurveyPriceCategoryUser(newVal);
      let currentSurveyPriceData = jsUtils.getSurveyPrice();
      let surveyPriceSetData = {
        Audience: {
          categoryUser: newVal,
          genPop: this.sampleType === 1 ? 1 : 0,
          sampleSize: this.sampleSize,
        },
      };
      jsUtils.setSurveyPrice({
        ...currentSurveyPriceData,
        ...surveyPriceSetData,
      });
      this.getSurveyTotalPrice();
    },
    sampleSize(newVal) {
      mutations.setSurveyPriceCategorySampleSize(newVal);
      let currentSurveyPriceData = jsUtils.getSurveyPrice();
      let surveyPriceSetData = {
        Audience: {
          ...currentSurveyPriceData.Audience,
          sampleSize: newVal,
          genPop: this.sampleType === 1 ? 1 : 0,
        },
      };
      jsUtils.setSurveyPrice({
        ...currentSurveyPriceData,
        ...surveyPriceSetData,
      });
      this.getSurveyTotalPrice();
    },
  },
  methods: {
    prevStep() {
      this.$router.push({
        name: this.$route.meta.audiencePrevRoute || "vid-key-metrics",
        params: { id: this.surveyID },
      });
    },
    nextStep() {
      let audients = {
        gender: this.sampleType === 1 ? ["male", "female"] : this.genders,
        age:
          this.sampleType === 1
            ? ["18-24", "25-34", "35-49", "50-59", "60+"]
            : this.ages,
        sampleSize: this.sampleSize,
        genPop: this.sampleType === 1 ? 1 : 0,
        categoryUser: this.sampleType === 2 ? this.usageFrame : 0,
      };

      let audientObj = {
        SurveyId: this.surveyID,
        model: audients,
        userId: this.user.EncrypteduserID,
      };

      let audienceDetails;

      if (this.sampleType === 1) {
        audienceDetails = "General population";
      } else if (this.sampleType === 2) {
        audienceDetails = `Category Users / Category usage: within last ${this.usageFrame} months`;
      } else {
        audienceDetails = `Custom / Gender: ${this.genders.join(
          ", "
        )} / Age: ${this.ages.join(", ")}`;
      }

      let surveySummary = {
        sampleSize: this.sampleSize,
        audience: audienceDetails,
      };

      let currentSummaryData = jsUtils.getSurveySummaryData();
      jsUtils.setSurveySummaryData({ ...currentSummaryData, ...surveySummary });

      const audiencePromise = new Promise((resolve) => {
        wsUtils.InsertAudienceDetails(audientObj, resolve);
      });

      audiencePromise.then(() => {
        jsUtils.setSurveysApiTrigger(true);

        this.$router.push({
          name: this.$route.meta.audienceNextRoute || "vid-launch",
          params: { id: this.surveyID },
        });
      });
    },
    updateQuiz() {
      this.quizs = [
        {
          quizName: this.langObj["s5-audience"].robot,
          completed: this.sampleType !== 0,
        },
        {
          quizName: this.langObj["s5-sample"].robot,
          completed: this.sampleSize >= 75,
        },
      ];
    },
  },
  activated() {
    this.sampleSize = 200;
    this.customSample = false;
    this.client = jsUtils.getCurClient();

    this.audienceName = sessionStorage.getItem("audienceName");

    this.surveyID = this.$route.params.id;

    this.updateQuiz();

    this.user = jsUtils.getUserInfo();

    this.editableSurvey = jsUtils.getEditableSurvey();
    if (
      this.editableSurvey.audience.age !== null &&
      this.editableSurvey.audience.gender !== null
    ) {
      if (this.editableSurvey.audience.categoryUser !== 0) {
        this.usageFrame = this.editableSurvey.audience.categoryUser;
        this.sampleType = 2;
      } else if (
        this.editableSurvey.audience.age.length === this.ages.length &&
        this.editableSurvey.audience.gender.length === this.genders.length
      ) {
        this.sampleType = 1;
      } else {
        this.sampleType = 3;
      }

      this.sampleSize = this.editableSurvey.audience.sampleSize;
      this.ages = this.editableSurvey.audience.age || [
        "18-24",
        "25-34",
        "35-49",
        "50-59",
        "60+",
      ];
      this.genders = this.editableSurvey.audience.gender || ["male", "female"];
    }
    this.getSurveyTotalPrice();
  },
  created() {
    this.langObj = jsUtils.getLangObj(
      "videoAuthorLang",
      "/tooltip/videoAuthor.json"
    );
    this.breadcrumbTitle =
      this.$route.name !== "pack-test-audience" ? "Video Ad" : "Pack";
  },
};
</script>

<style lang="scss" scoped>
.questions {
  padding: 2em;
  border: solid 1px var(--lineGrey);
  border-radius: 8px 8px 0 0;
  width: 50vw;
  display: grid;
  align-self: center;
}

.quizContent .ant-radio-group {
  display: flex;
  // place-content: space-between;
}

.quizTitle {
  margin-bottom: 2em;
}

.break {
  display: flex;
  // place-content: space-between;
  align-items: center;
  // margin-top: 2em;
  column-gap: 10px;

  .ant-btn-sm {
    font-size: 13px;
    padding: 2px 10px;
  }
}

.quiz {
  margin-top: 2em;
}

.refine {
  border-radius: 4px;
  padding: 2em;
  border: 1px solid #ddd;
  background-color: rgba(255, 255, 255, 0.85);
  margin-top: 0;

  .ant-checkbox-group {
    margin-bottom: 2em;
    white-space: nowrap;
  }

  .ant-divider-inner-text {
    font-weight: normal;
  }
}
</style>
